import { create } from 'zustand';
import {
   Account,
   AddressHistory,
   AdEvent,
   CustomerContactMethodFull,
   Note,
   PinwheelLinkFull,
   Message,
   ReferralDetails,
   CustomerFull,
   ConsentFull,
   InAppMessage,
   LighthouseTag,
   ActivityLog,
   EarnGrant,
   PlaidLinkFull,
   PlaidTransaction,
   CustomerStateHistory,
   Workflow,
   Customer,
   EarnTask,
   AddOn,
   UserConsent,
   UserSetting,
   SavingsAccount,
   BridgeBuck,
   BraintreeTransaction,
   TilloTransaction,
   FinixTransaction,
   UserTag,
   Streak,
   StreakEvent,
   StreakReward,
   RentReward,
   RentRewardEvent,
   RentRewardStats,
   SardineMetadata,
   FriendRelationship,
   FriendActivity,
   FriendLike,
   TilloCashOut,
   SleekTransaction,
   SailfishMemberQuestionAnswer,
   SailfishSurvey,
   SailfishProfile,
   SailfishEntryRecord,
   ArcadeEvent,
   UserGame,
} from 'types';
import { FetchEarnFeedResponse, PaginatedResponse } from 'services';
import { ActionEvent } from '../types/actionEvent';
import { GameEvent } from '../types/gameEvent';
import { SurveyEvent } from '../types/surveyEvent';
import { AffiliateEvent } from '../types/affiliateEvents';
import { CustomerioResponse } from '../services/responses/CustomerioResponse';

interface MemberStoreData {
   accountInfo: Account | null;
   actionEventsInfo: PaginatedResponse<ActionEvent> | null;
   activityLogsInfo: PaginatedResponse<ActivityLog> | null;
   adEventsInfo: PaginatedResponse<AdEvent> | null;
   addOnInfo: PaginatedResponse<AddOn> | null;
   addressInfo: AddressHistory[] | null;
   arcadeEventsInfo: PaginatedResponse<ArcadeEvent> | null;
   affiliateEventsInfo: PaginatedResponse<AffiliateEvent> | null;
   affiliatesFeedInfo: FetchEarnFeedResponse | null;
   braintreeInfo: PaginatedResponse<BraintreeTransaction> | null;
   bridgeBucksInfo: PaginatedResponse<BridgeBuck> | null;
   cioMessagesInfo: CustomerioResponse | null;
   contactInfo: CustomerContactMethodFull[] | null;
   earnFeedInfo: FetchEarnFeedResponse | null;
   earnGrantsInfo: PaginatedResponse<EarnGrant> | null;
   earnTaskInfo: PaginatedResponse<EarnTask> | null;
   finixInfo: PaginatedResponse<FinixTransaction> | null;
   friendActivitiesInfo: PaginatedResponse<FriendActivity> | null;
   friendFollowedByInfo: PaginatedResponse<FriendRelationship> | null;
   friendFollowsInfo: PaginatedResponse<FriendRelationship> | null;
   friendLikesInfo: PaginatedResponse<FriendLike> | null;
   gameEventsInfo: PaginatedResponse<GameEvent> | null;
   inAppMessagesInfo: PaginatedResponse<InAppMessage> | null;
   lighthouseTags: PaginatedResponse<LighthouseTag> | null;
   member: CustomerFull | null;
   members: PaginatedResponse<Customer> | null;
   messagesInfo: PaginatedResponse<Message<any>> | null;
   notesInfo: PaginatedResponse<Note> | null;
   notificationConsents: ConsentFull[] | null;
   pinwheelLinksInfo: PinwheelLinkFull[] | null;
   plaidLinksInfo: PaginatedResponse<PlaidLinkFull> | null;
   plaidTransactionsInfo: PaginatedResponse<PlaidTransaction> | null;
   referralCodesInfo: ReferralDetails | null;
   rentRewardsEventsInfo: PaginatedResponse<RentRewardEvent> | null;
   rentRewardsInfo: RentReward | null;
   rentRewardsStats: RentRewardStats | null;
   sailfishAnswersInfo: PaginatedResponse<SailfishMemberQuestionAnswer> | null;
   sardineInfo: PaginatedResponse<SardineMetadata> | null;
   savingsInfo: SavingsAccount | null;
   sleekTransactionsInfo: PaginatedResponse<SleekTransaction> | null;
   stateHistoryInfo: CustomerStateHistory[] | null;
   streakEventsInfo: PaginatedResponse<StreakEvent> | null;
   streakRewardsInfo: PaginatedResponse<StreakReward> | null;
   streaksInfo: PaginatedResponse<Streak> | null;
   surveyEventsInfo: PaginatedResponse<SurveyEvent> | null;
   tasksNames: string[];
   tilloCashOutsInfo: PaginatedResponse<TilloCashOut> | null;
   tilloInfo: PaginatedResponse<TilloTransaction> | null;
   userConsentsInfo: PaginatedResponse<UserConsent> | null;
   userSettingsInfo: PaginatedResponse<UserSetting> | null;
   userTagsInfo: PaginatedResponse<UserTag>;
   workflowsInfo: PaginatedResponse<Workflow> | null;
   qualifiedSurveysInfo: SailfishSurvey[];
   surveysEntryLinks: any;
   sailfishProfileInfo: SailfishProfile | null;
   sailfishEntryRecordsInfo: PaginatedResponse<SailfishEntryRecord> | null;
   userGamesInfo: PaginatedResponse<UserGame> | null;
}

interface MemberStoreActions {
   clear: () => void;

   hasAddon: (name: string) => boolean;
   setAccountInfo: (accountInfo: Account) => void;
   setActionEventsInfo: (actionEventsInfo: PaginatedResponse<ActionEvent> | null) => void;
   setActivityLogsInfo: (activityLogsInfo: PaginatedResponse<ActivityLog> | null) => void;
   setAdEventsInfo: (adEventsInfo: PaginatedResponse<AdEvent> | null) => void;
   setAddOnInfo: (addOnInfo: PaginatedResponse<AddOn> | null) => void;
   setAddressInfo: (addressInfo: AddressHistory[] | null) => void;
   setArcadeEventsInfo: (arcadeEventsInfo: PaginatedResponse<ArcadeEvent> | null) => void;
   setAffiliateEventsInfo: (affiliateEventsInfo: PaginatedResponse<AffiliateEvent> | null) => void;
   setAffiliatesFeedInfo: (affiliatesFeedInfo: FetchEarnFeedResponse) => void;
   setBraintreeInfo: (braintreeInfo: PaginatedResponse<BraintreeTransaction> | null) => void;
   setBridgeBucksInfo: (bridgeBucksInfo: PaginatedResponse<BridgeBuck> | null) => void;
   setCIOMessagesInfo: (cioMessagesInfo: CustomerioResponse | null) => void;
   setContactInfo: (contactInfo: CustomerContactMethodFull[]) => void;
   setEarnFeedInfo: (earnFeedInfo: FetchEarnFeedResponse) => void;
   setEarnGrantsInfo: (earnGrantsInfo: PaginatedResponse<EarnGrant>) => void;
   setEarnTaskInfo: (earnTaskInfo: PaginatedResponse<EarnTask>) => void;
   setFriendActivitiesInfo: (friendActivitiesInfo: PaginatedResponse<FriendActivity> | null) => void;
   setFriendFollowedByInfo: (friendFollowedByInfo: PaginatedResponse<FriendRelationship> | null) => void;
   setFriendFollowsInfo: (friendFollowsInfo: PaginatedResponse<FriendRelationship> | null) => void;
   setFriendLikesInfo: (friendLikesInfo: PaginatedResponse<FriendLike> | null) => void;
   setGameEventsInfo: (gameEventsInfo: PaginatedResponse<GameEvent> | null) => void;
   setInAppMessagesInfo: (InAppMessagesInfo: PaginatedResponse<InAppMessage>) => void;
   setLighthouseTags: (lighthouseTags: PaginatedResponse<LighthouseTag>) => void;
   setMember: (member: CustomerFull | null) => void;
   setMembers: (members: PaginatedResponse<Customer>) => void;
   setMessagesInfo: (messagesInfo: PaginatedResponse<Message<any>>) => void;
   setNotesInfo: (notesInfo: PaginatedResponse<Note>) => void;
   setNotificationConsents: (notificationConsents: ConsentFull[]) => void;
   setPinwheelLinksInfo: (pinwheelLinksInfo: PinwheelLinkFull[]) => void;
   setPlaidLinksInfo: (plaidLinksInfo: PaginatedResponse<PlaidLinkFull> | null) => void;
   setPlaidTransactionsInfo: (plaidTransactionsInfo: PaginatedResponse<PlaidTransaction> | null) => void;
   setReferralCodesInfo: (referralCodesInfo: ReferralDetails) => void;
   setRentRewardsEventsInfo: (rentRewardsEventsInfo: PaginatedResponse<RentRewardEvent> | null) => void;
   setRentRewardsInfo: (rentRewardsInfo: RentReward | null) => void;
   setRentRewardsStatsInfo: (rentRewardsStats: RentRewardStats | null) => void;
   setSailfishAnswersInfo: (sailfishAnswersInfo: PaginatedResponse<SailfishMemberQuestionAnswer> | null) => void;
   setSardineInfo: (sardineInfo: PaginatedResponse<SardineMetadata> | null) => void;
   setSavingsInfo: (savingsInfo: SavingsAccount | null) => void;
   setSleekTransactionsInfo: (sleekTransactionsInfo: PaginatedResponse<SleekTransaction> | null) => void;
   setStateHistoryInfo: (stateHistoryInfo: CustomerStateHistory[] | null) => void;
   setStreakEventsInfo: (streakEventsInfo: PaginatedResponse<StreakEvent> | null) => void;
   setStreakRewardsInfo: (streakRewardsInfo: PaginatedResponse<StreakReward> | null) => void;
   setStreaksInfo: (streaksInfo: PaginatedResponse<Streak> | null) => void;
   setSurveyEventsInfo: (surveyEventsInfo: PaginatedResponse<SurveyEvent> | null) => void;
   setTasksNames: (tasksNames: string[]) => void;
   setTilloCashOutsInfo: (tilloCashOutsInfo: PaginatedResponse<TilloCashOut> | null) => void;
   setTilloInfo: (tilloInfo: PaginatedResponse<TilloTransaction> | null) => void;
   setUserConsentsInfo: (userConsentsInfo: PaginatedResponse<UserConsent> | null) => void;
   setUserSettingsInfo: (userSettingsInfo: PaginatedResponse<UserSetting> | null) => void;
   setUserTagsInfo: (userTagsInfo: PaginatedResponse<UserTag>) => void;
   setWorkflowsInfo: (workflowsInfo: PaginatedResponse<Workflow> | null) => void;
   setQualifiedSurveysInfo: (qualifiedSurveysInfo: SailfishSurvey[]) => void;
   setSurveysEntryLinks: (surveysEntryLinks: any) => void;
   setSailfishEntryRecordsInfo: (sailfishEntryRecordsInfo: PaginatedResponse<SailfishEntryRecord> | null) => void;
   setSailfishProfileInfo: (sailfishProfileInfo: SailfishProfile | null) => void;
   setUserGamesInfo: (userGamesInfo: PaginatedResponse<UserGame> | null) => void;
}

export type MemberStore = MemberStoreData & MemberStoreActions;

const initialState: MemberStoreData = {
   accountInfo: null,
   userTagsInfo: {
      records: [],
      total: 0,
   },
   activityLogsInfo: null,
   addressInfo: null,
   adEventsInfo: null,
   actionEventsInfo: null,
   gameEventsInfo: null,
   surveyEventsInfo: null,
   arcadeEventsInfo: null,
   affiliateEventsInfo: null,
   addOnInfo: null,
   contactInfo: null,
   earnFeedInfo: null,
   affiliatesFeedInfo: null,
   earnGrantsInfo: null,
   earnTaskInfo: null,
   inAppMessagesInfo: null,
   lighthouseTags: null,
   member: null,
   members: null,
   messagesInfo: null,
   cioMessagesInfo: null,
   notesInfo: null,
   notificationConsents: null,
   pinwheelLinksInfo: null,
   plaidLinksInfo: null,
   plaidTransactionsInfo: null,
   referralCodesInfo: null,
   stateHistoryInfo: null,
   userConsentsInfo: null,
   userSettingsInfo: null,
   workflowsInfo: null,
   savingsInfo: null,
   tasksNames: [],
   bridgeBucksInfo: null,
   braintreeInfo: null,
   finixInfo: null,
   tilloInfo: null,
   sailfishAnswersInfo: null,
   streaksInfo: null,
   streakEventsInfo: null,
   streakRewardsInfo: null,
   rentRewardsInfo: null,
   rentRewardsEventsInfo: null,
   rentRewardsStats: null,
   sardineInfo: null,
   friendFollowsInfo: null,
   friendFollowedByInfo: null,
   friendActivitiesInfo: null,
   friendLikesInfo: null,
   tilloCashOutsInfo: null,
   sleekTransactionsInfo: null,
   qualifiedSurveysInfo: [],
   surveysEntryLinks: {},
   sailfishProfileInfo: null,
   sailfishEntryRecordsInfo: null,
   userGamesInfo: null,
};

export const useMemberStore = create<MemberStore>()(
   (set, get): MemberStore => ({
      ...initialState,
      clear: () => {
         const { lighthouseTags, tasksNames, ...rest } = { ...initialState };
         set(rest);
      },

      setMember: (member: CustomerFull | null) => {
         set({ member });
      },
      setMembers: (members: PaginatedResponse<Customer>) => set({ members }),
      setUserTagsInfo: (userTagsInfo: PaginatedResponse<UserTag>) => {
         set({ userTagsInfo });
      },
      setActivityLogsInfo: (activityLogsInfo: PaginatedResponse<ActivityLog> | null) => set({ activityLogsInfo }),
      setAccountInfo: (accountInfo: Account) => set({ accountInfo }),
      setAddressInfo: (addressInfo: AddressHistory[] | null) => set({ addressInfo }),
      setAdEventsInfo: (adEventsInfo: PaginatedResponse<AdEvent> | null) => set({ adEventsInfo }),
      setActionEventsInfo: (actionEventsInfo: PaginatedResponse<ActionEvent> | null) => set({ actionEventsInfo }),
      setGameEventsInfo: (gameEventsInfo: PaginatedResponse<GameEvent> | null) => set({ gameEventsInfo }),
      setSurveyEventsInfo: (surveyEventsInfo: PaginatedResponse<SurveyEvent> | null) => set({ surveyEventsInfo }),
      setArcadeEventsInfo: (arcadeEventsInfo: PaginatedResponse<ArcadeEvent> | null) =>
         set({
            arcadeEventsInfo,
         }),
      setAffiliateEventsInfo: (affiliateEventsInfo: PaginatedResponse<AffiliateEvent> | null) =>
         set({ affiliateEventsInfo }),
      setAddOnInfo: (addOnInfo: PaginatedResponse<AddOn> | null) => set({ addOnInfo }),
      setContactInfo: (contactInfo: CustomerContactMethodFull[]) => set({ contactInfo }),
      setEarnFeedInfo: (earnFeedInfo: FetchEarnFeedResponse) => set({ earnFeedInfo }),
      setAffiliatesFeedInfo: (affiliatesFeedInfo: FetchEarnFeedResponse) => set({ affiliatesFeedInfo }),
      setEarnTaskInfo: (earnTaskInfo: PaginatedResponse<EarnTask>) => set({ earnTaskInfo }),
      setEarnGrantsInfo: (earnGrantsInfo: PaginatedResponse<EarnGrant>) => set({ earnGrantsInfo }),
      setInAppMessagesInfo: (inAppMessagesInfo: PaginatedResponse<InAppMessage>) => set({ inAppMessagesInfo }),
      setLighthouseTags: (lighthouseTags: PaginatedResponse<LighthouseTag>) => set({ lighthouseTags }),
      setMessagesInfo: (messagesInfo: PaginatedResponse<Message<any>>) => set({ messagesInfo }),
      setCIOMessagesInfo: (cioMessagesInfo: CustomerioResponse | null) => set({ cioMessagesInfo }),
      setNotesInfo: (notesInfo: PaginatedResponse<Note>) => set({ notesInfo }),
      setNotificationConsents: (notificationConsents: ConsentFull[]) => set({ notificationConsents }),
      setPinwheelLinksInfo: (pinwheelLinksInfo: PinwheelLinkFull[]) => set({ pinwheelLinksInfo }),
      setPlaidLinksInfo: (plaidLinksInfo: PaginatedResponse<PlaidLinkFull> | null) => set({ plaidLinksInfo }),
      setPlaidTransactionsInfo: (plaidTransactionsInfo: PaginatedResponse<PlaidTransaction> | null) =>
         set({ plaidTransactionsInfo }),
      setReferralCodesInfo: (referralCodesInfo: ReferralDetails) => set({ referralCodesInfo }),
      setStateHistoryInfo: (stateHistoryInfo: CustomerStateHistory[] | null) => set({ stateHistoryInfo }),
      setUserConsentsInfo: (userConsentsInfo: PaginatedResponse<UserConsent> | null) => set({ userConsentsInfo }),
      setUserSettingsInfo: (userSettingsInfo: PaginatedResponse<UserSetting> | null) => set({ userSettingsInfo }),
      setWorkflowsInfo: (workflowsInfo: PaginatedResponse<Workflow> | null) => set({ workflowsInfo }),
      setSavingsInfo: (savingsInfo: SavingsAccount | null) => set({ savingsInfo }),
      setBridgeBucksInfo: (bridgeBucksInfo: PaginatedResponse<BridgeBuck> | null) => set({ bridgeBucksInfo }),
      setBraintreeInfo: (braintreeInfo: PaginatedResponse<BraintreeTransaction> | null) => set({ braintreeInfo }),
      setTilloInfo: (tilloInfo: PaginatedResponse<TilloTransaction> | null) => set({ tilloInfo }),
      setSailfishAnswersInfo: (sailfishAnswersInfo: PaginatedResponse<SailfishMemberQuestionAnswer> | null) =>
         set({ sailfishAnswersInfo }),
      setStreaksInfo: (streaksInfo: PaginatedResponse<Streak> | null) => set({ streaksInfo }),
      setStreakEventsInfo: (streakEventsInfo: PaginatedResponse<StreakEvent> | null) => set({ streakEventsInfo }),
      setStreakRewardsInfo: (streakRewardsInfo: PaginatedResponse<StreakReward> | null) => set({ streakRewardsInfo }),
      hasAddon: (name: string) => {
         const info = get().addOnInfo;

         if (info) {
            return info.records.some((a: AddOn) => a.name === name);
         }

         return false;
      },
      setTasksNames: (tasksNames: string[]) => set({ tasksNames }),
      setRentRewardsInfo: (rentRewardsInfo: RentReward | null) => set({ rentRewardsInfo }),
      setRentRewardsEventsInfo: (rentRewardsEventsInfo: PaginatedResponse<RentRewardEvent> | null) =>
         set({ rentRewardsEventsInfo }),
      setRentRewardsStatsInfo: (rentRewardsStats: RentRewardStats | null) => set({ rentRewardsStats }),
      setSardineInfo: (sardineInfo: PaginatedResponse<SardineMetadata> | null) => set({ sardineInfo }),
      setFriendFollowsInfo: (friendFollowsInfo: PaginatedResponse<FriendRelationship> | null) =>
         set({ friendFollowsInfo }),
      setFriendFollowedByInfo: (friendFollowedByInfo: PaginatedResponse<FriendRelationship> | null) =>
         set({ friendFollowedByInfo }),
      setFriendActivitiesInfo: (friendActivitiesInfo: PaginatedResponse<FriendActivity> | null) =>
         set({ friendActivitiesInfo }),
      setFriendLikesInfo: (friendLikesInfo: PaginatedResponse<FriendLike> | null) => set({ friendLikesInfo }),
      setTilloCashOutsInfo: (tilloCashOutsInfo: PaginatedResponse<TilloCashOut> | null) => set({ tilloCashOutsInfo }),
      setSleekTransactionsInfo: (sleekTransactionsInfo: PaginatedResponse<SleekTransaction> | null) =>
         set({ sleekTransactionsInfo }),
      setQualifiedSurveysInfo: (qualifiedSurveysInfo: SailfishSurvey[]) => set({ qualifiedSurveysInfo }),
      setSurveysEntryLinks: (surveysEntryLinks: any) => set({ surveysEntryLinks }),
      setSailfishEntryRecordsInfo: (sailfishEntryRecordsInfo: PaginatedResponse<SailfishEntryRecord> | null) =>
         set({
            sailfishEntryRecordsInfo,
         }),
      setSailfishProfileInfo: (sailfishProfileInfo: SailfishProfile | null) => set({ sailfishProfileInfo }),
      setUserGamesInfo: (userGamesInfo: PaginatedResponse<UserGame> | null) => set({ userGamesInfo }),
   }),
);
